import React from "react";
import { Wrapper } from "./globals/wrappers";
import styled from "styled-components";
import MotionHeadline from "./motion/motion-headline";
import ImageBox from "./motion/motion-box/image-box";

const PageMarqueeWrapper = styled(Wrapper)`
  margin-top: calc(6rem + 8vw);
  margin-bottom: 6vw;

  font-family: "Orbikular", serif;

  em {
    font-family: "Aeonik", sans-serif;
  }

  .page-headline {
    max-width: 8em;
  }
`;
const PageMarqueeImage = styled(ImageBox)`
  margin-top: 6vw;
  margin-bottom: 6vw;
`;
export function PageMarquee({ headline, image }) {
  return (
    <PageMarqueeWrapper>
      <MotionHeadline text={headline} className="h1 page-headline" />
      {image && <PageMarqueeImage fluid={image.fluid} alt={image.alt} />}
    </PageMarqueeWrapper>
  );
}
