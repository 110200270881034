/**
 * Vendorh
 */
import axios from "axios";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";

/**
 * Local Components
 */
import InquireInputs from "./inquire-inputs";

/**
 * Functions
 */
import getFormSchema from "../../functions/getFormSchema";
import getFormInitialValues from "../../functions/getFormInitialValues";
import InquireSuccess from "./inquire-success";

// pre-fill form using query params (date yyyy-MM-dd)

// ?name=John&email=hi%2Bcramdyn@john.design&phone=5555555555&company=jco&budget=1-5&project_details=deets
// ?name=TestTest&email=hi%2Bcramdyn@john.design&phone=5555555555&company=jco&budget=1-5&project_details=TestTest

export default function InquireForm({ formData, search }) {
  const [complete, setComplete] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const currentPageData = formData.pages[currentPageIndex - 1];
  const currentPageQuestions = currentPageData?.questions || [];
  const schema = getFormSchema(currentPageQuestions);
  const searchParamObject = Object.fromEntries(new URLSearchParams(search));
  const initialValues = getFormInitialValues(
    searchParamObject,
    currentPageQuestions
  );
  const resolver = yupResolver(schema);

  const { control, handleSubmit, setError, getValues, formState } = useForm({
    defaultValues: initialValues,
    mode: "onTouched",
    resolver,
    shouldUseNativeValidation: true,
  });

  const totalPages = formData.pages.length;
  const lastPage =
    currentPageIndex === totalPages || currentPageIndex > totalPages;

  function onError(error) {
    Object.entries(error).forEach(([name, errorObj]) =>
      setError(name, { type: errorObj.type, message: errorObj.message })
    );
  }

  async function onSubmit(values) {
    if (!lastPage) {
      setCurrentPageIndex(currentPageIndex + 1);
    } else {
      const data = schema.cast(values);
      await axios
        .post("/.netlify/functions/sendEmail", JSON.stringify(data))
        .then((response) => {
          const hasErrors = response?.data?.some((_data) => !_data?.id);
          if (response.status !== 200 && !hasErrors) {
            console.error("error: ", response);
          } else {
            setComplete(true);
          }
        })
        .catch((error) => console.error(error));
    }
  }

  return (
    <InquireWrapper>
      {!complete && (
        <InquireInputs
          control={control}
          currentPageData={currentPageData}
          currentPageIndex={currentPageIndex}
          currentPageQuestions={currentPageQuestions}
          formData={formData}
          getValues={getValues}
          handleSubmit={handleSubmit}
          lastPage={lastPage}
          onError={onError}
          onSubmit={onSubmit}
          setCurrentPageIndex={setCurrentPageIndex}
          isFormSubmitting={formState.isSubmitting}
        />
      )}
      {complete && <InquireSuccess setComplete={setComplete} />}
    </InquireWrapper>
  );
}

const InquireWrapper = styled.div`
  display: flex;
  margin: 2rem auto;

  @media ${({ theme }) => theme.device.tablet} {
    margin: ${({ theme }) => theme.layout.space[300]} auto;
    padding: 0 ${({ theme }) => theme.layout.space[300]};
  }
`;

InquireForm.propTypes = {
  formData: PropTypes.objectOf(PropTypes.array).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  search: PropTypes.any.isRequired,
};
