import { motion, useAnimation } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { animation } from "../../style/theme";

import SvgLoader from "../svg";
import InputBasic from "./input-basic";

function SelectArrowDef() {
  return (
    <svg width="0" height="0">
      <defs>
        <clipPath id="caret">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 10c4.056 0 6 3.46 6 6h2c0-2.54 1.944-6 6-6V7c-2.76 0-5.12 2.17-6.098 4.163-.53 1.079-.748 1.868-.838 2.337h-.127c-.09-.469-.31-1.258-.839-2.337C10.12 9.17 7.76 7 5 7v3z"
            fill="#F5D171"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default function InputGroup({
  component,
  question,
  name,
  onBlur,
  onChange,
  value,
  errorMessage,
  isValid,
  ...rest
}) {
  const Input = component || InputBasic;
  const [labelWidth, setLabelWidth] = React.useState();
  const labelRef = React.useRef([]);

  const controls = useAnimation();

  React.useEffect(() => {
    if (value) {
      controls.start("active");
    }
    if (!value) {
      controls.start("initial");
    }
  }, [controls, value]);

  React.useEffect(() => {
    setLabelWidth(labelRef?.current?.offsetWidth);
  }, [labelRef]);

  const labelVariants = {
    initial: {
      scale: 1,
      opacity: 1,
    },
    active: {
      scale: 0.75,
      opacity: 0.5,
    },
  };

  const labelIconVariants = {
    initial: {
      opacity: 1,
      scale: 1,
    },
    active: {
      opacity: 0,
      scale: 0.5,
    },
  };

  const editIconVariants = {
    initial: {
      opacity: 0,
      scale: 0.5,
    },
    active: {
      opacity: 1,
      scale: 1,
    },
  };

  return (
    <InputWrap
      key={question.question_key}
      data-error={!!errorMessage}
      data-type={`${question.question_type}`}
      labelWidth={labelWidth}
      isValid={isValid}
      data-has-value={value ? true : false}
    >
      <InputLabel
        className="p-title"
        data-required={question.required}
        htmlFor={name}
        variants={labelVariants}
        initial="initial"
        animate={controls}
        transition={{ ease: animation.timingFunction.js, duration: 0.6 }}
      >
        <span ref={labelRef}>{question.title}</span>
        <SvgWrap
          variants={labelIconVariants}
          initial="initial"
          animate={controls}
          transition={{ ease: animation.timingFunction.js, duration: 0.6 }}
        >
          <SvgLoader svg="Edit" />
        </SvgWrap>
      </InputLabel>
      <Input
        data-testid={`input_${question.question_key}`}
        name={name}
        onFocus={() => controls.start("active")}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={question.placeholder}
        value={value}
        {...rest}
      />
      {errorMessage && (
        <ErrorText
          className="p-small"
          data-testid={`error_text_${question.question_key}`}
        >
          {errorMessage}
        </ErrorText>
      )}
      <SelectArrowDef />
      <SvgEditWrap
        variants={editIconVariants}
        initial="initial"
        animate={controls}
        transition={{ ease: animation.timingFunction.js, duration: 0.6 }}
      >
        <SvgLoader svg="Edit" />
      </SvgEditWrap>
    </InputWrap>
  );
}

const SvgWrap = styled(motion.span)`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  @media ${({ theme }) => theme.device.tablet} {
    width: 2rem;
    height: 2rem;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;

const SvgEditWrap = styled(motion.span)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: var(--hpad);
  top: 2rem;
  z-index: 2;

  svg {
    width: 1.15rem;
    height: 1.15rem;
  }

  @media ${({ theme }) => theme.device.tablet} {
    top: var(--vpad);
    width: 2rem;
    height: 2rem;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;

const InputLabel = styled(motion.label)`
  color: ${({ theme }) => theme.colors.green};
  position: absolute;
  z-index: 1;
  top: 1.35rem;
  left: var(--hpad);
  line-height: 130%;
  font-size: ${({ theme }) => theme.fonts.paragraphLarge};
  transform-origin: left center;
  display: flex;
  align-items: center;

  @media ${({ theme }) => theme.device.tablet} {
    top: calc(var(--vpad) * 0.8);
    font-size: ${({ theme }) => theme.fonts.h5};
  }

  &,
  * {
    cursor: auto;
    pointer-events: none;
  }

  &[data-required="true"] {
    &:after {
      content: "";
      position: absolute;
      right: -0.5em;
      top: 0.5em;
      width: 0.1875rem;
      height: 0.1875rem;
      border-radius: 0.1875rem;
      background-color: ${({ theme }) => theme.colors.red};
    }
  }
`;

const ErrorText = styled(motion.p)`
  color: ${({ theme }) => theme.colors.green};
  position: absolute;
  top: 0.65rem;
  right: var(--hpad);
  line-height: 0.8125rem;

  @media ${({ theme }) => theme.device.tablet} {
    top: 0.875rem;
  }
`;

const InputWrap = styled.div`
  --vpad: 1.5rem;
  --hpad: 1rem;
  --font: ${({ theme }) => theme.fonts.paragraphLarge};
  --height: calc(calc(var(--vpad) * 2) + var(--font));
  height: var(--height);
  cursor: auto !important;
  display: flex;
  position: relative;
  transition: transform ${({ theme }) => theme.animation.duration[100].css}
    ${({ theme }) => theme.animation.timingFunction.css};
  will-change: transform;
  --label-width: ${({ labelWidth }) => (labelWidth ? labelWidth : 100)}px;

  @media ${({ theme }) => theme.device.tablet} {
    --vpad: 2rem;
    --hpad: 2.5rem;
    --font: ${({ theme }) => theme.fonts.h5};
  }

  &:hover {
    input,
    select {
      transform: translate3d(0, -0.1rem, 0);
    }

    &[data-type="date_picker"]:after,
    &[data-type="dropdown"]:after {
      transform: translate3d(0, -0.1rem, 0);
    }
  }

  &:active {
    transform: scale(1);

    &[data-type="date_picker"]:after,
    &[data-type="dropdown"]:after {
      transform: translate3d(0, 0.15rem, 0);
    }
  }

  input,
  select,
  textarea {
    cursor: auto;
    background: rgba(255, 255, 255, 0.5);
    color: ${({ theme }) => theme.colors.green};
    border-radius: 0.25rem;
    font-family: "DomaineSansTextRegular", sans-serif;
    font-style: normal;
    font-size: ${({ theme }) => theme.fonts.h5};
    line-height: 130%;
    resize: none;
    font-feature-settings: "ss05" on, "ss03" on, "ss07" on, "calt" off,
      "liga" off;
    transition: transform, background, color, border, padding;
    transition-duration: ${({ theme }) => theme.animation.duration[100].css};
    transition-timing-function: ${({ theme }) =>
      theme.animation.timingFunction.css};
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    position: absolute;
    border: none;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    z-index: 0;

    &:focus,
    &:active {
      background: rgba(255, 255, 255, 0.75);
      outline: none;
    }

    &::placeholder {
      color: rgba(15, 39, 7, 0.4);
      display: none;
    }
  }

  input {
    padding: var(--vpad) var(--hpad);
    padding-left: calc(var(--hpad) + calc(var(--label-width) * 0.75) + 1rem);
    padding-right: 4rem;
  }

  select {
    padding: calc(var(--vpad) * 0.8) var(--hpad);
    padding-left: calc(var(--hpad) + calc(var(--label-width) * 0.75) + 1rem);
  }

  textarea {
    padding: calc(var(--vpad) * 2) calc(var(--hpad) * 0.95);
    padding-bottom: 1rem;
  }

  &[data-error="true"] {
    input,
    select,
    textarea {
    }
  }

  &[data-type="text_area"] {
    height: 12rem;
  }

  &[data-type="date_picker"]:after,
  &[data-type="dropdown"]:after {
    content: "";
    width: 1.5em;
    height: 1.5em;
    background-color: ${({ theme }) => theme.colors.golden};
    position: absolute;
    top: 2.375rem;
    right: 0.9rem;
    clip-path: url(#caret);
    transition: transform;
    transition-duration: ${({ theme }) => theme.animation.duration[100].css};
    transition-timing-function: ${({ theme }) =>
      theme.animation.timingFunction.css};
    will-change: transform;
  }

  input[type="date"] {
    &::-webkit-datetime-edit-text {
      color: ${({ theme }) => theme.colors.grayLight};
      padding: 0 0.3em;
    }
    &::-webkit-inner-spin-button {
      display: none;
    }
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
`;
