import React from "react";
import styled from "styled-components";
import { hoverChange } from "../../functions/util";
import Box from "../motion/motion-box";
import MotionHeadline from "../motion/motion-headline";
import SvgLoader from "../svg";

const SuccessWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  a {
    margin: 2rem auto;
    color: rgba(255, 255, 255, 0.5);
  }
`;

const SuccessBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 30vw;
  padding: 10vw 0;
  background: rgba(255, 255, 255, 0.5);
  color: ${({ theme }) => theme.colors.green};
  border-radius: 0.25rem;

  svg {
    margin-bottom: 3rem;
    width: 5rem;
    height: 5rem;
  }
`;

const ButtonGroupWrapper = styled.div`
  --vpad: 2rem;
  --hpad: 2.5rem;
  --font: ${({ theme }) => theme.fonts.h5};
  --height: calc(calc(var(--vpad) * 2) + var(--font));
  display: flex;
  position: relative;
  bottom: 0;
  width: 100%;
  height: var(--height);

  &:hover {
    button {
      transform: translate3d(0, -0.1rem, 0);
    }
  }

  button {
    display: flex;
    padding: var(--vpad) var(--hpad);
    margin: 1rem auto;
    width: 100%;
    height: var(--height);
    border: none;
    align-items: center;
    justify-content: center;
    font-size: var(--font);
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.white};
    border-radius: 0.25rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    position: absolute;
    border: none;
    width: 100%;
    height: 100%;
    transition: transform, background, color, border, padding;
    transition-duration: ${({ theme }) => theme.animation.duration[100].css};
    transition-timing-function: ${({ theme }) =>
      theme.animation.timingFunction.css};
  }
`;

function InquireSuccess({ setComplete }) {
  return (
    <SuccessWrapper>
      <SuccessBox>
        <SvgLoader svg="Checkmark" color="green" />
        <MotionHeadline className="h4" text={"Project"} />
        <MotionHeadline className="h4" text={"Submitted"} />
      </SuccessBox>
      <ButtonGroupWrapper>
        <button
          onClick={() => setComplete(false)}
          onMouseEnter={() => hoverChange("link")}
          onMouseUp={() => hoverChange(null)}
          onMouseLeave={() => hoverChange(null)}
        >
          <MotionHeadline className="h6" text={"Submit Another"} />
        </button>
      </ButtonGroupWrapper>
    </SuccessWrapper>
  );
}

export default InquireSuccess;
