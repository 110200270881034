import React from "react";

export default function InputTextArea({
  name,
  onBlur,
  onChange,
  placeholder,
  value,
  ...rest
}) {
  return (
    <textarea
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      rows="15"
      {...rest}
    />
  );
}
