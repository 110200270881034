/* eslint-disable */

import React from "react";

export default function InputSelect({
  id,
  name,
  onBlur,
  onChange,
  value,
  menuItems,
  ...rest
}) {
  return (
    <select
      id={id}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      {...rest}
    >
      <option value=""></option>
      {menuItems.map(({ label, value: _value }, index) => (
        <option key={`${_value}-${index}`} value={_value}>
          {label}
        </option>
      ))}
    </select>
  );
}
