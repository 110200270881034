import React from "react";
import styled from "styled-components";
import { hoverChange } from "../../functions/util";

export default function InquireButtonGroup({ isFormSubmitting }) {
  return (
    <ButtonGroupWrapper>
      <button
        type="submit"
        color="white"
        disabled={isFormSubmitting}
        onMouseEnter={() => hoverChange("link")}
        onMouseUp={() => hoverChange(null)}
        onMouseLeave={() => hoverChange(null)}
      >
        {isFormSubmitting ? "Submitting..." : "Submit"}
      </button>
    </ButtonGroupWrapper>
  );
}
const ButtonGroupWrapper = styled.div`
  --vpad: 2rem;
  --hpad: 2.5rem;
  --font: ${({ theme }) => theme.fonts.h5};
  --height: calc(calc(var(--vpad) * 2) + var(--font));
  display: flex;
  position: relative;
  bottom: 0;
  width: 100%;
  height: var(--height);

  &:hover {
    button {
      transform: translate3d(0, -0.1rem, 0);
    }
  }

  button {
    display: flex;
    padding: var(--vpad) var(--hpad);
    margin: 1rem auto;
    width: 100%;
    height: var(--height);
    border: none;
    align-items: center;
    justify-content: center;
    font-size: var(--font);
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.black};
    border-radius: 0.25rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    position: absolute;
    border: none;
    width: 100%;
    height: 100%;
    transition: transform, background, color, border, padding;
    transition-duration: ${({ theme }) => theme.animation.duration[100].css};
    transition-timing-function: ${({ theme }) =>
      theme.animation.timingFunction.css};
  }
`;
