import React from "react";
import { graphql } from "gatsby";

import { Wrapper } from "../components/globals/wrappers";
import formData from "../data/form.json";
import InquireForm from "../components/inquire-form/index";
import { PageMarquee } from "../components/page-marquee";
import MotionHeadline from "../components/motion/motion-headline";
import { ContactSection } from "../components/contact-section";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function Contact({
  data: {
    prismicContactpage: { data },
  },
  location: { search },
}) {
  return (
    <>
      <PageMarquee headline={data?.marquee_headline.text} />
      <Wrapper>
        <MotionHeadline text="Start a project" className="h6 section-title" />
        <InquireForm formData={formData} search={search} />
      </Wrapper>
      <ContactSection data={data} />
    </>
  );
}

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query PrismicContactpage($uid: String!) {
    prismicContactpage(uid: { eq: $uid }) {
      uid
      data {
        title
        marquee_headline {
          text
        }
        contact_title
        contact_text {
          text
          raw
          html
        }
      }
    }
  }
`;
